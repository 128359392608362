import * as React from "react";
import { Link } from "react-router-dom";

class Nav extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }
    componentWillMount() {
        this.setState({logoText: "HM"});
    }

    handleMouseEnter(e) {
        this.setState({logoText: "Heinrich Malan"});
    }

    handleMouseLeave(e) {
        this.setState({logoText: "HM"});
    }

    render() {
        return (
            <div>
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                    <Link className="navbar-brand m-0 p-0" to="/">
                        {/*<img src="../logo.png" width={30} height={30} className="d-inline-block align-top mr-1"/>*/}
                        <div onMouseLeave={this.handleMouseLeave} onMouseEnter={this.handleMouseEnter} className="d-inline-flex align-items-center justify-content-center p-1 logo">
                            <strong>{this.state.logoText}</strong>
                        </div>
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div className="navbar-nav">
                        <span className="nav-item nav-link"><Link className="text-white-50" to="/cv">CV</Link></span>
                        <span className="nav-item nav-link"><Link className="text-white-50" to="/blog">Blog</Link></span>
                        <span className="nav-item nav-link"><Link className="text-white-50" to="/contact">Contact</Link></span>
                        </div>
                    </div>
                </nav>
            </div>
        )
    }
}

export default Nav;
