import * as React from "react";

export default class FourOhFour extends React.Component {
    render() {
        return (
            <div className="row w-100 d-flex justify-content-center align-items-center">
                <div>
                <h1>This Page Does Not Exist</h1>
                </div>
            </div>
        )
    }
}