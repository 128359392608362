import { resolve } from "path";

export class BlogPostData {
    postId: String;
    title: String;
    content: String;
    date: Date;

    constructor() {
        this.postId = "";
        this.title = "";
        this.content = "";
        this.date = new Date();
    }
}

export function getPostDetails(postId: string): Promise<BlogPostData> {
    return new Promise((resolve, reject) => {

        let url: string = `/api/blog/${postId}`;

        fetch(url)
        .then(res => {
            if(res.status == 200) {
                return res.json();
            } else {
                reject("Failed getting post details");
            }
        })
        .then(resultJson => {

            let post: BlogPostData = new BlogPostData();
            post.postId = resultJson.post._id;
            post.title = resultJson.post.title;
            post.content = resultJson.post.content;
            post.date = resultJson.post.date;
            resolve(post);
        })
        .catch(err => {
            reject(err);
        })
    })
}

export function createOrUpdatePost(post: BlogPostData, token: string): Promise<any> {
        return new Promise((resolve, reject) => {
            fetch("/api/blog", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "Authorization": token
                },
                body: JSON.stringify(post)
            })
            .then(data => {
                if(data.status == 200) {
                    return data.json()
                } else {
                    reject(data);
                }
            })
            .then(jsonData => {
                resolve(jsonData);
            })
            .catch(err => {
                //Handle error here
                console.log(err);
                reject(err);
            })
        })
        
}