import React from "react";

class Contact extends React.Component {
    render() {
        return (
            <div className="row w-100 d-flex flex-column align-items-center justify-content-center">
                <div>Email<i className="fas fa-envelope"></i><a className="text-dark" href="mailto:heinrich@heinbio.me">heinrich@heinbio.me</a></div>
                <div>LinkedIn<i className="fab fa-linkedin"></i><a className="text-dark" target="_blank" href="https://www.linkedin.com/in/heinrichmalan">https://www.linkedin.com/in/heinrichmalan</a></div>
                <div>GitHub<i className="fab fa-github-square"></i><a className="text-dark" target="_blank" href="https://github.com/heinrichmalan">https://github.com/heinrichmalan</a></div>
            </div>
        )
    }
}

export default Contact;