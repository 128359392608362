import React from "react";
import { Link } from "react-router-dom";

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: ""
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(e) {
        let target = e.target.name;
        let value = e.target.value;
        this.setState({[target]: value});
    }

    handleSubmit(e) {
        e.preventDefault();
        let url = "/api/user/login";
        let options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify({
                "username": this.state.username,
                "password": this.state.password
            })
        }

        fetch(url, options)
        .then(res => {
            return res.json();
        })
        .then(data => {
            document.cookie = `token=${data.token}`;
            localStorage.setItem("token", data.token);
            window.location = "/";
        })
        .catch(err => {
            this.state.error = err;
        })
    }
    
    render() {
        return (
            <div style={{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                {/*ALL HTML MUST BE WITHIN THIS DIV*/}
                {this.state.error ?
                    <div>
                        {this.state.error}
                    </div>
                    : null}
                <form onSubmit={this.handleSubmit}>
                    <label>
                        Username<br />
                        <input name="username" value={this.state.username} onChange={this.handleInputChange} type="text"></input>
                    </label><br />
                    <label>
                        Password<br />
                        <input name="password" value={this.state.password} onChange={this.handleInputChange} type="password"></input>
                    </label><br />
                    <input type="submit" value="Login"></input>
                </form>
            </div>
        )
    }
}

export default Login;