import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import MediaQuery from'react-responsive';


class BlogPost extends Component {
    constructor(props) {
        super(props);
        this.state = this.props.postDetails

        this.divRef = React.createRef();
	this.deletePost = this.deletePost.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState(props.postDetails, () => {
            this.divRef.current.innerHTML = this.state.content
            this.render()
        });
    }
    
    componentDidMount() {
        this.divRef.current.innerHTML = this.state.content
    }

    deletePost() {
        let url = `/api/blog/${this.props.postDetails._id}`;
    	let options = {
    		method: "DELETE",
    		headers: { 
    			"Content-Type": "application/json; charset=utf-8",
    			"Authorization": localStorage.getItem("token")
    		}
    	}
    	fetch(url, options)
    	.then(data => {
            if(data.status != 200) return Promise.reject("Post not deleted"); 
    		window.location = "/blog";
    	}).catch(err => {
            console.log(err);
    	})
    }
    
    render() {
        
        let style = {
            width: "21cm",
            padding: "5px", 
            margin: "5px",
            maxHeight: "default",
            overflow: "hidden",
            backgroundColor: "#fafafa",
            boxShadow: "0px 0px 3px rgba(0,0,0,0.5)"
        }

        let resStyle = {};
        Object.assign(resStyle, style);
        resStyle.width = "100%";

        {/** Set appropriate styles depending on preview state */}
        this.props.preview ? (style.maxHeight = "500px") : (style.boxShadow = "none", style.backgroundColor="#ffffff", style.maxHeight="none");

        {/** Make the title a link if not in preview state */}
        const title = this.props.preview ? <Link to={`/blog/${this.state._id}`} style={{color: "black"}}>{this.state.title}</Link> : this.state.title;
        return (
            <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "center"}}>
                <MediaQuery query="(min-width: 21cm)">
                    <div style={style}> <h2>{title}</h2> <p><i>{moment(this.state.date).format("dddd MMMM D, YYYY")}</i></p> <div ref={this.divRef}>
                        </div>

			{!this.props.preview && localStorage.getItem("token") && !this.props.edit ? 
				<button onClick={this.deletePost}>Delete</button>
			 : ""}
                    </div>
                </MediaQuery>
                <MediaQuery query="(max-width: 21cm)">
                    <div style={resStyle}>
                        <h2>{title}</h2>
                        <p><i>{moment(this.state.date).format("dddd MMMM D, YYYY")}</i></p>
                        <div ref={this.divRef}>
                        </div>
                    </div>
                </MediaQuery>
            </div>
        );
    }
}

export default BlogPost;
