import React from "react";
import BlogPost from "./BlogPost";
import styled from "styled-components";
const moment = require('moment');

const BlogGrid = styled.div`
    display: grid;
    grid-template-columns: 100%;
    width: 100%;

    @media (min-width: 576px) {
        grid-template-columns: auto 576px auto;
    }

    @media (min-width: 768px) {
        grid-template-columns: auto 576px 288px auto;
    }

    @media (min-width: 992px) {
        grid-template-columns: auto 704px 288px auto;
    }
`;

const PostContainer = styled.div`
    grid-column-start: 1;
    grid-column-end: 2;

    @media (min-width: 576px) {
        grid-column-start: 2;
        grid-column-end: 3;
    }
`;

class Blog extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            posts: []
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.postId) {
            this.setState({postId: nextProps.match.params.postId}, this.getPostDetails);    
        } else {
            this.getLatestPosts();
        }
    }
    
    componentDidMount() {
        
        if (this.props.match && this.props.match.params.postId) {
            this.setState({postId: this.props.match.params.postId}, this.getPostDetails);            
        } else {
            this.getLatestPosts();
        }
    }

    setPosts(postDetails, isPreview) {
        const sortedPosts = postDetails.sort((a,b) => {
            let aDate = moment(a.date).valueOf();
            let bDate = moment(b.date).valueOf();
            console.log(typeof aDate);
            return bDate - aDate;
        })
        const posts = sortedPosts.map(post => {
            return <BlogPost postDetails={post} preview={isPreview} key={post._id}/>
        })
        this.setState({posts: posts});
    }

    getPostDetails() {
        fetch(`/api/blog/${this.state.postId}`)
        .then((result) => {
            return result.json();
        }).then(result => {
            this.setPosts([result.post], false);
        })
        .catch((err) => {
            //console.log(err);
        });
    }
    
    getLatestPosts() {
        fetch('/api/blog')
        .then((result) => {
            return result.json();
        })
        .then(resultJson => {
            this.setPosts(resultJson, true);
        })
        .catch((err) => {
            //console.log(err);
        });
    }
    
    render() {
        return (
            <BlogGrid>
                <PostContainer>{this.state.posts}</PostContainer>
            </BlogGrid>
        )
    }
}

export default Blog;