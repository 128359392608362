import * as React from "react";
import { render}  from "react-dom";
import { BrowserRouter, Route, Link, Switch } from "react-router-dom";

import Nav from "./components/Nav";
import Home from "./components/Home";
import Resume from "./components/Resume";
import Blog from "./components/Blog";
import Contact from "./components/Contact";
import About from "./components/About";
import FourOhFour from "./components/FourOhFour";
import Footer from "./components/Footer";
import BlogPost from "./components/BlogPost";
import Login from "./components/Login";
import CreateBlogPost from "./components/CreateBlogPost";

class App extends React.Component<any, any> {
	render() {
  return (
    <BrowserRouter>
      <div>
        <Nav />
        <div className="row content">
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/cv" component={Resume} />
            <Route path="/contact" component={Contact} />
            <Route path="/blog" component={Blog} exact/>
            <Route path="/blog/create" component={CreateBlogPost} exact/>
            <Route path="/blog/edit/:postId" component={CreateBlogPost} exact/>
            <Route path="/blog/:postId" component={Blog} exact/>
            <Route path="/login" component={Login} />
            <Route component={FourOhFour} />
          </Switch>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  )
	}
};
export default App;
render(<App />, document.getElementById("app"));
