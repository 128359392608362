import React from "react";
import { Link } from "react-router-dom";
import BlogPost from "./BlogPost";
import { getPostDetails, createOrUpdatePost } from "../helpers/blog-helper";

class CreateBlogPost extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
                title: "",
                content: "",
                date: new Date(),
                postId: null
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if(this.props.match && this.props.match.params.postId) {
            getPostDetails(this.props.match.params.postId)
            .then(data => {
                let currentState = this.state;
                currentState.title = data.title;
                currentState.content = data.content;
                currentState.date = data.date;
                currentState.postId = data.postId
                this.setState(currentState);
            })
        }
    }

    handleInputChange(event) {
        let target = event.target;
        let name = target.name;

        this.setState({
            [name]: target.value
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        if(!localStorage.token || localStorage.token == undefined) return;
        createOrUpdatePost(this.state, localStorage.getItem("token"))
        .then(data => {
            if(data.status != 200) return Promise.reject(data);
            window.location = "/blog";
        })
        .catch(err => {
            console.log(err);
        })
    }
    
    render() {
        return (
            <div style={{width: "100vw", height: "100%"}}>
                <div style={{display: "grid", gridTemplateColumns: "50% 50%"}}>
                    <div id="blog-input" style={{gridColumnStart: "1", gridColumnEnd: "2", height: "100%", padding: "15px"}}>
                        <form id="blog-form" onSubmit={this.handleSubmit}>
                            <label id="blog-title-label" htmlFor="title">Blog Title</label>
                            <br />
                            <input style={{width: "100%"}} type="text" name="title" id="create-blog-title" onChange={this.handleInputChange} value={this.state.title}/>
                            <br />
                            <label id="blog-title-label" htmlFor="content">Blog Contents</label>
                            <br />
                            <textarea style={{width: "100%", height: "80vh"}} name="content" onChange={this.handleInputChange} value={this.state.content} />
                            <br />
                            <button type="submit">{this.state.postId == null ? "Create" : "Edit"} Post</button>
                        </form>
                    </div>
                    <div id="blog-output" style={{gridColumnStart: "2", gridColumnEnd: "3"}}>
                        <BlogPost edit={true} postDetails={this.state}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default CreateBlogPost;
