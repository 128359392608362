import * as React from "react";
import { Link } from "react-router-dom"

class Home extends React.Component<any, any> {
    render() {
        return (
            <div style={{display: 'flex', flexDirection: 'column', width: '100vw'}}>
                <div className="row img-container home-img img-row">
                    <div className="row home-img-darken">
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%'}} className="text-light about-summary">
                            <div><h2>Back-End</h2></div>
                            <div><h2>Front-End</h2></div>
                            <div><h2>And everything in between</h2></div>
                        </div>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '2em'}}>
                    <h1>Hi</h1>
                    <h2>My name is <span className="text-secondary">Heinrich Malan</span></h2>
                    <h3>And I'm an <u>Engineer</u></h3>
                    <br />
                    <p>I have Bachelor's Degrees in Computer Science and Medical Sciences.
                    <br />
                    I'm passionate about technology and how we can use it to make our lives better.
                    <br />
                    I'm looking forward to working with other people who want to leave a positive mark upon our world.
                    <br />
                    I still have a lot to learn - and am always looking for chances to do so!
                    </p>
                </div>
                {/* <div className="row latest-blog-post img-row">
                    <div className="home-img-darken"></div>
                </div> */}
            </div>
        )
    }
}

export default Home;
