import * as React from "react";
import {Link} from "react-router-dom";

class Footer extends React.Component<any, any> {
    render() {
        return (
            <div className="row bg-dark text-white-50">
                <div className="row w-100 d-flex justify-content-around">
                <div>
                    <Link className="m-1 text-light" to="/">Home</Link>
                    <Link className="m-1 text-light"  to="/cv">CV</Link>
                    <Link className="m-1 text-light"  to="/blog">Blog</Link>
                    <Link className="m-1 text-light"  to="/contact">Contact</Link>
                </div>
                </div>
                <div className="row w-100 d-flex justify-content-around">
                </div>
            </div>
        )
    }
}

export default Footer;
