import * as React from "react";
import { Link } from "react-router-dom";

interface ResumeItemProps {
    companyName: String
    jobTitle: String
    yearFrom: Number
    yearTo?: Number
    children?: React.ReactNode
}

const ResumeItem = ({companyName, jobTitle, yearFrom, yearTo, children}: ResumeItemProps) => {
    return <div style={{display:'grid', gridTemplateColumns: 'auto auto', gridTemplateRows: 'repeat(4, auto)'}}>
        
        <strong style={{gridColumn: 0/1, gridRow: 0/1, justifySelf: 'end', paddingRight: '5px'}}>Company</strong>
        <span style={{gridColumn: 1/2, gridRow: 0/1}}>{companyName}</span>
    
        <strong style={{gridColumn: 0/1, gridRow: 0/1, justifySelf: 'end', paddingRight: '5px'}}>Job Title</strong>
        <span style={{gridColumn: 1/2, gridRow: 1/2}}>{jobTitle}</span>
    
    
        <strong style={{gridColumn: 0/1, gridRow: 0/1, justifySelf: 'end', paddingRight: '5px'}}>Duration</strong>
        <span style={{gridColumn: 1/2, gridRow: 2/3}}>{yearFrom} - {yearTo ? yearTo : "Current"}</span>
    
    
        <strong style={{gridColumn: 0/1, gridRow: 0/1, justifySelf: 'end', paddingRight: '5px'}}>Responsibilities</strong>
        <span style={{gridColumn: 1/2, gridRow: 3/4}}>{children}</span>
            
    </div>
}

interface EducationItemProps {
    universityName: String
    degree: String
    yearFrom: Number
    yearTo?: Number
    children?: React.ReactNode
}

const EducationItem = ({universityName, degree, yearFrom, yearTo, children}: EducationItemProps) => {
    return <div style={{display:'grid', gridTemplateColumns: 'auto auto', gridTemplateRows: 'repeat(4, auto)'}}>
        
        <strong style={{gridColumn: 0/1, gridRow: 0/1, justifySelf: 'end', paddingRight: '5px'}}>University</strong>
        <span style={{gridColumn: 1/2, gridRow: 0/1}}>{universityName}</span>
    
        <strong style={{gridColumn: 0/1, gridRow: 0/1, justifySelf: 'end', paddingRight: '5px'}}>Degree</strong>
        <span style={{gridColumn: 1/2, gridRow: 1/2}}>{degree}</span>
    
    
        <strong style={{gridColumn: 0/1, gridRow: 0/1, justifySelf: 'end', paddingRight: '5px'}}>Duration</strong>
        <span style={{gridColumn: 1/2, gridRow: 2/3}}>{yearFrom} - {yearTo ? yearTo : "Current"}</span>
            
    </div>
}

const Resume = () => {
    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', margin: '2em auto 0 auto', maxWidth: '800px'}}>
            {/*ALL HTML MUST BE WITHIN THIS DIV*/}
            <h3>Employment History</h3>
            <ResumeItem companyName="Borg Collective GmbH" jobTitle="Backend Software Engineer" yearFrom={2019}>
                <div style={{display: 'flex', flexDirection:'column'}}>
                    <span>Development and Optimisation of Django/Flask API</span>
                    <span>Development of Express.js Backend</span>
                    <span>Setup and Maintenance of Infrastructure on AWS</span>
                    <span>Development of ETL Pipelines using AWS Lambda and Celery</span>
                    <span>Data Engineering for SQL and NoSQL databases</span>
                    <span>Migration from Monolothic to Microservices Based Architecture</span>
                    <span>Hiring, training and mentoring of new engineers</span>
                    <span>Review and Testing of Code</span>
                </div>
            </ResumeItem>
            <br />
            <ResumeItem companyName="E.A & Associates Engineers" jobTitle="Consulting Software Engineer" yearFrom={2021}>
                <div style={{display: 'flex', flexDirection:'column'}}>
                    <span>Development of Invoice Application</span>
                    <span>Simplification of Employee Activity Data Entry and automated invoice generation for clients</span>
                    <span>Development of Frontend (Next.js), Backend (Django) hosted on Vercel and Google Cloud Platform</span>
                </div>
            </ResumeItem>
            <br />
            <ResumeItem companyName="E.A & Associates Engineers" jobTitle="General IT Staff" yearFrom={2015} yearTo={2019}>
                <div style={{display: 'flex', flexDirection:'column'}}>
                    <span>Rebuild of Company Website</span>
                    <span>Workflow Optimisation and Automation</span>
                    <span>Hardware Maintenance and Troubleshooting</span>
                </div>
            </ResumeItem>
            <br />
            <h3>Education</h3>
            <EducationItem universityName={"The University of Sydney"} degree="B. Computer Science and Technology" yearFrom={2015} yearTo={2019}></EducationItem>
            <br />
            <EducationItem universityName={"Macquarie University"} degree="B. Medical Sciences" yearFrom={2011} yearTo={2014}></EducationItem>
        </div>
    )
}

export default Resume;